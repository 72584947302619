.sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 18rem !important;
}
@media (max-width: 767.98px) {
    .sidebar:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0 !important;
    }
}
@media (min-width: 768px) {
    .sidebar.hide:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 0 !important;
    }
}
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
.text-align-center{
    text-align: center;
}
.read-notification {
    background-color: #fff; /* Example color for read notifications */
  }
  
  .unread-notification {
   
    background-color: #e55353 /* Example color for unread notifications */
  }